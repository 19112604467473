/* eslint-disable prefer-destructuring */
/* eslint-disable max-lines */
import { delay } from 'redux-saga'
import {
  allPass,
  compose,
  contains,
  filter,
  find,
  has,
  hasIn,
  head,
  is,
  isEmpty,
  isNil,
  keys,
  map,
  mapObjIndexed,
  not,
  path,
  pathOr,
  pick,
  pickBy,
  prop,
  propEq,
  propOr,
  reduce,
  reject,
  update,
  values,
} from 'ramda'

import { change, clearFields, getFormSyncErrors, getFormValues, initialize, reset } from 'redux-form'
import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects'

import {
  getDataForReservationUpdate,
  goToPage,
  removeNotify,
  setReservationCustomerInfo,
  setReservationPassengerInfo,
  setViewMode,
  showNotify,
  updateEditableFields,
  submitDriverDetails,
  showModal,
} from '../../../actions'

import { payment, pricing, reservation as reservationService, selections, user } from '../../../services'

import AccessToHistory from '../../../modules/Common/AccessToHistory'
import { getReservation } from '../../../services/reservation/api/reservation'
import {
  clearTimestamps,
  manageSchedulePartsFetchResponse,
  setTimestamp,
} from '../../../services/schedule/actions/schedule'
import { manageSailPackagesFetchResponse } from '../../../services/routes/sailPackages/actions'

import {
  createReservationForManyGenerator,
  createReservationWithCharterTicket,
  updateMultiCatsReservationGenerator,
  updateReservationWithCharterTicket,
} from '../reservationPrepare'
import { updateVehicle, watchUpdateVehicle } from './updateVehicleSaga'
import { watchModifyReservation } from '../../../modules/Ticket/EditTicket/editTicketSagas'
import { warningSign } from '../../../consts/stuff'
import { getSelectedSailRefIds, sails } from '../../../services/schedule/reducers'
import {
  clearAllTempPassengerData,
  getEditableTrip,
  getSelectedSailPackages,
  resetEditTrip,
  setIsChaterAdding,
} from '../../../services/user-selections'
import * as Actions from '../../../services/reservation/consts/reservation'

import { reservationModificationPassengerEditService } from '../../../services/reservation/api'
import { FORM_MODE_COMPANY } from '../../../modules/Booking/forms/CustomerInfo/consts'
import { getDateISOString } from '../../../modules/Booking/forms/CustomerInfo/Passengers-Date.util'
import { allPropsFilled, getNextGuestSeqNum } from '../../../modules/Booking/forms/CustomerInfo/PassengersHeap.util'
import {
  CLEAR_PASSENGER_SEQ_NUM,
  REMOVE_ONE_PASSENGER_FORM_DATA,
  SELECT_PASSENGER_TO_EDIT_PERSONAL_DATA,
} from '../../../services/user-selections/consts'
import { getLastRequest } from '../../../reducers/network'
import { promoteResidentDiscount } from '../../../services/user/actions'
import { isBluewave, isMaritime } from '../../../utils/maritimeStyleUtils'
import { addMember, modifyMember } from '../../../services/passengers-data/actions'
import { getMembers, getSavedGuests } from '../../../services/passengers-data/selectors'
import { customerSelector } from '../../../services/customer/selectors'
import { removeFeeItemsFromReservation } from '../../../services/reservation/utils'
import { writeDriverDetailsIfNeeded } from './driverDetails'

const {
  startFetchingReservation,
  startPriceCalculation,
  createReservation,
  modifyReservation,
  getReservationParts,
  getReservationFailed,
  setRefundReservation,
  fetchPaymentMethods,
  refundSuccess,
  watchCancelledReservation,
} = reservationService.actions.reservation

const { sendTransactionStatus, paymentReplaceLocation } = payment.actions
const { clearPrices, setPricesState } = pricing.actions
const { CALCULATE_VEHICLE_PRICE_SUCCESS } = pricing.consts

const {
  setSailDates,
  changeItemQtty,
  selectCustomerRole,
  changeLocalTravellersQuantity,
  setSelectedPackages,
  getCustomerRole,
  addSeat,
  flushSeats,
} = selections

const { getUserIdSelector, getCustomerIdSelector } = user.selectors
const { getCurrentReservation } = reservationService.selectors

const {
  RESERVATION_CREATE_FAILED,
  RESERVATION_CREATED,
  RESERVATION_MODIFIED,
  RESERVATION_MODIFY_FAILED,
  UPDATE_LOCAL_RESERVATION,
  GET_RESERVATION_PARTS_SUCCESS,
  REMOVE_CURRENT_RESERVATION,
  MANAGE_URL_RESERVATION,
  RESERVATION_EXPIRED,
  RESERVATION_CONFIRM_SUCCESS,
} = reservationService.constants

const { SEND_TRANSACTION_STATUS_SUCCESS, IMMEDIATELY_PAYMENT_PAYED } = payment.constants

export const getTicketsFormValues = getFormValues('ticketsForm') // for redux-saga-test-plan
export const getLocalTravellerFormValues = getFormValues('localTravellerIDForm') // for redux-saga-test-plan

function* goToTickets({ payload: reservationId }) {
  // Not sure if it is ever used in non DEV env
  // But here should be not reservationId, but the token to make an url
  yield put(goToPage(`/ticket/${reservationId}`))
}

function* goToRefundSuccess() {
  yield put(goToPage('/refund/results'))
}

export function* clearReservationIfNeeded({ payload }) {
  const reservation = yield select(getCurrentReservation)

  yield put(change('departure', 'sailTime', 0))
  yield put(resetEditTrip())
  const modal = yield select((state) => state.modal)
  if (reservation) {
    const { sailPackages = [] } = reservation

    // we should clear ALL packages, because on second step we have
    // logic connected with TWO packages only.
    // IT SHOULD BE CHANGED AFTER BASKET LOGIC AND LOGIC WITH A SAILPACKAGES MORE THAN 2
    if (sailPackages.length) {
      if (!modal || modal === '' || modal !== 'datetime') {
        yield put(clearPrices())
        yield put({ type: REMOVE_CURRENT_RESERVATION })
      }
      yield put(reset('ticketsForm'))
      yield put(reset('localTravellerIDForm'))
      yield put(reset('vehiclesForm'))
      yield put(reset('trailersForm'))
      yield put(initialize('ticketsForm', {}))
      yield put(
        initialize('localTravellerIDForm', {
          localIDs: [
            {
              localID: '',
              status: 'not-checked',
            },
          ],
        })
      )
      yield put(initialize('vehiclesForm', {}))
      yield put(initialize('trailersForm', {}))
    }
  }

  try {
    if (payload) {
      const sailsState = yield select(sails)
      const sailsTimes = (sailsState[payload.sailPackageCode] || []).map(({ departure, sailRefId }) => ({
        sailRefId,
        timestamp: departure.timestamp,
      }))
      const selectedDateTime = sailsTimes.find(({ sailRefId }) => sailRefId === payload.selectedSailId)
      if (selectedDateTime)
        yield put(
          setTimestamp({
            sailPackageCode: payload.sailPackageCode,
            selected: selectedDateTime.timestamp,
          })
        )
      else yield put(clearTimestamps())
    }
  } catch (e) {
    console.warn('There is an error setting selected timestamps', e)
  }
}

function* clearCurrentReservation() {
  yield put({ type: REMOVE_CURRENT_RESERVATION })
  yield put({ type: CLEAR_PASSENGER_SEQ_NUM })
  yield put(clearAllTempPassengerData())

  const getEditReservation = path(['editTicket', 'reservation'])
  const reservationOnEdit = yield select(getEditReservation)
  if (reservationOnEdit && location.href.endsWith('myticket-edit')) {
    const { token = '' } = reservationOnEdit
    yield put(goToPage(`/ticket/${token}`))
    return
  }
  yield put(goToPage('/'))
}

function* showAlert(action) {
  switch (action.type) {
    case RESERVATION_CREATED:
    case 'inventory/RESERVATION_CREATED': {
      const accessToHistory = new AccessToHistory()
      if (accessToHistory.history) {
        // accessToHistory.history.push('/main-inside/confirm')
      }
      window.scrollTo(0, 0)

      break
    }

    case RESERVATION_CREATE_FAILED: {
      window.scrollTo(0, 0)
      const error = action.payload

      yield put(
        showNotify({
          notification: {
            type: 'error',
            title: `${error.status}: ${error.message}`,
          },
        })
      )

      yield delay(4000)
      yield put(removeNotify(0))

      break
    }

    default:
      break
  }
}

export function* updateReservationByUrl({ payload: { urlSearchParams = '', reservationToken, reservationType } }) {
  if (reservationToken) {
    yield put(fetchPaymentMethods(reservationToken))

    if (reservationType !== 'refund') yield put({ type: REMOVE_CURRENT_RESERVATION })

    try {
      yield put(startFetchingReservation())
      const paymentReservation = yield call(getReservation, reservationToken)
      const { reservation = { status: '' } } = paymentReservation

      if (reservation.status && reservation.status.startsWith('CN')) {
        yield put(setViewMode('cancelled'))
        yield put(watchCancelledReservation(reservation))
      }

      yield put(getDataForReservationUpdate(paymentReservation))

      if (reservationType === 'refund') {
        yield put(setRefundReservation(paymentReservation.reservation))
      } else if (urlSearchParams && !urlSearchParams.startsWith('?showEdit')) {
        yield put(
          sendTransactionStatus({
            urlSearchParams,
            paymentReservation,
          })
        )
      } else {
        yield put({
          type: SEND_TRANSACTION_STATUS_SUCCESS,
          payload: { paymentReservation },
        })
      }
    } catch (error) {
      yield put(getReservationFailed(error))
    }
  } else {
    const currentReservation = yield select(getCurrentReservation)

    if (currentReservation) {
      const { token } = currentReservation
      yield put(fetchPaymentMethods(token))
    }
  }
}

function* handleReservationConfirm() {
  const fields = yield select(pathOr({}, ['form', 'ticketsForm', 'values']))
  yield put(updateEditableFields(fields))
  yield put(goToPage('/main-inside/confirm'))
  yield put({ type: CLEAR_PASSENGER_SEQ_NUM })
  yield put(clearAllTempPassengerData())
}

export function* fetchDataForReservationUpdate({ payload: paymentReservation }) {
  yield put(getReservationParts({ reservationToken: paymentReservation.reservation.token }))
}

export function* setReservationsParts({ payload = {} }) {
  const {
    availableDates,
    inventories,
    prices,
    sailPackages,
    sails,
    reservationDates,
    reservationSails: selectedSails,
  } = payload

  yield put(setPricesState(prices))
  yield put(manageSailPackagesFetchResponse(sailPackages))

  const getSelectedSailPackageFromCode = (code) => {
    const sailPackage = find(propEq('code', code), sailPackages.content) || {}
    const { route = { legs: [] } } = sailPackage

    return {
      code,
      route: route.code,
      legSequence: filter(propEq('code', code), route.legs),
      source: 'saga',
    }
  }

  const selectedDates = Object.values(reservationDates)
  const selectedPackages = map(getSelectedSailPackageFromCode, Object.keys(sails))

  yield put(
    manageSchedulePartsFetchResponse({
      availableDates,
      inventories,
      sails,
      selectedSails,
    })
  )
  yield put(setSailDates(selectedDates))
  yield put(setSelectedPackages(selectedPackages))
}

function* updateReservationPaymentStatus({ payload: { paymentReservation } }) {
  yield put({
    type: UPDATE_LOCAL_RESERVATION,
    payload: { ...paymentReservation },
  })
  // window.scrollTo(0, 0)
}

export function* modifyReservationOwner({ payload }) {
  const getEditReservation = path(['editTicket', 'reservation'])
  const reservationOnEdit = yield select(getEditReservation)
  if (reservationOnEdit && location.href.endsWith('myticket-edit')) return

  const { companyId: companyIdFromPayload } = payload || {}
  const getContactFormValues = pathOr({}, ['form', 'contact', 'values'])
  const getContactFormErrors = pathOr(null, ['form', 'contact', 'syncErrors'])
  const isValidContact = allPass([
    has('firstName'),
    has('lastName'),
    has('phoneNumber'),
    has('phoneCode'),
    has('email'),
  ])
  const notEmpty = compose(not, isEmpty)
  const getNotEmptyContactValues = compose(pickBy(notEmpty), getContactFormValues)
  const noContactFromErrors = compose(isNil, getContactFormErrors)

  const state = yield select()
  const customer = yield select(customerSelector)
  let values = getNotEmptyContactValues(state)
  const countries = pathOr([], ['user', 'countries'], state)

  const userId = yield select(getUserIdSelector)
  const customerId = yield select(getCustomerIdSelector)
  const customerRole = yield select(getCustomerRole)

  const companyId =
    companyIdFromPayload || (customerRole && customerRole.registrationNumber ? customerRole.code : undefined)

  const fallBackCustomerData = {
    firstName: customer.firstName,
    lastName: customer.lastName,
    phoneNumber: pathOr('', ['phone', 'phoneNumber'])(customer),
    phoneCode: pathOr('', ['phone', 'intlCode'])(customer),
    email: customer.email,
  }

  if (!isValidContact(values) && customer.customerId) {
    console.warn('Alert! Contacts form data is empty, filling with fallback object')
    values = { ...fallBackCustomerData }
  }

  if (isValidContact(values) && noContactFromErrors(state)) {
    const currentReservation = yield select(getCurrentReservation)

    if (currentReservation) {
      const { reservationId, token } = currentReservation

      const { firstName, lastName, phoneCode: intlCode, phoneNumber, email } = values
      const { legalName, country, city, addressLine, registrationNumber, zip } = values

      const {
        registrationNumber: registrationNumberFromPayload,
        name: legalNameFromPayload,
        addressLine: addressLineNameFromPayload,
        city: cityFromPayload,
        zip: zipFromPayload,
      } = customerRole || {}

      const isCompany = values.formMode
        ? values.formMode === FORM_MODE_COMPANY && Boolean(legalName)
        : Boolean(legalName)

      const companyInfo = {
        legalName: legalNameFromPayload ? legalNameFromPayload : legalName,
        address: addressLineNameFromPayload ? addressLineNameFromPayload : addressLine,
        registrationNumber: registrationNumberFromPayload ? registrationNumberFromPayload : registrationNumber,
        city: cityFromPayload ? cityFromPayload : city,
        zip: zipFromPayload ? zipFromPayload : zip,
        country: path(['code'], countries[country]),
      }

      // updateOwnerInfo
      const reservationOwner = {
        ...(userId && { userId }),
        ...(customerId && { customerId }),
        firstName,
        lastName,
        email,
        companyId,
        phone: {
          intlCode,
          phoneNumber,
        },
        ...(isCompany || companyIdFromPayload ? { companyInfo } : {}),
      }

      yield put(
        modifyReservation({
          values,
          newReservation: {
            reservationOwner,
            reservationId,
            token,
          },
        })
      )
    }
  }
}

export function* modifyPassengerInfo({ payload }) {
  const getBookingFormValues = pathOr({}, ['form', 'bookingForm', 'values', 'passengers', payload.index])
  const getBookingFormErrors = pathOr(null, ['form', 'bookingForm', 'syncErrors', 'passengers', payload.index])
  const isValidBooking = allPropsFilled
  const notEmpty = compose(not, isEmpty)
  const getNotEmptyBookingValues = compose(pickBy(notEmpty), getBookingFormValues)
  const noBookingFormErrors = compose(isNil, getBookingFormErrors)

  const state = yield select()
  const values = getNotEmptyBookingValues(state)
  const formErrors = getFormSyncErrors('bookingForm')(state).passengers
    ? getFormSyncErrors('bookingForm')(state).passengers[payload.index]
    : null
  const errorFields = []
  if (formErrors) {
    Object.keys(formErrors).forEach((field) => {
      if (values[field] === undefined || values[field] === null) {
        errorFields.push(values[field])
      }
    })
  }

  if (isValidBooking(values) && (noBookingFormErrors(state) || errorFields.length === 0)) {
    const currentReservation = yield select(getCurrentReservation)

    if (currentReservation) {
      const { reservationId } = currentReservation

      const { firstName, lastName, seqN, gender = 'M', birthday = {}, citizenship, optionalInfo, customerId } = values
      const shouldSaveData = pathOr(false, ['form', 'shouldSaveDataForm', 'values', seqN])(state)
      const birthDayISO = getDateISOString(birthday)

      if (shouldSaveData) {
        const members = yield select(getMembers)
        const savedGuests = yield select(getSavedGuests)
        const alreadySavedGuestWithCurrentSeqN = savedGuests.find(({ seqN: savedGuestSeqN }) => savedGuestSeqN === seqN)
        const isGuestWithCurrentSeqNAlreadySaved = Boolean(alreadySavedGuestWithCurrentSeqN)

        if (isGuestWithCurrentSeqNAlreadySaved || customerId) {
          const { customerId: customerIdOfAlreadySavedGuest } = alreadySavedGuestWithCurrentSeqN || {}
          const currentCustomerId = customerId || customerIdOfAlreadySavedGuest
          const memberWithCurrentCustomerId =
            members.find(({ customerId: memberCustomerId }) => memberCustomerId === currentCustomerId) || {}
          const { companies } = memberWithCurrentCustomerId
          const companyIds = companies.map(({ companyId }) => companyId)
          yield put(
            modifyMember({
              id: {
                customerBoraId: currentCustomerId,
              },
              firstName,
              lastName,
              gender,
              birthday: birthDayISO,
              companyIds,
            })
          )
        } else {
          yield put(
            addMember({
              seqN,
              firstName,
              lastName,
              birthday: birthDayISO,
              gender,
              companyIds: [],
            })
          )
        }
      }

      const citizenshipList = yield select((state) => state.user.citizenships || [])
      let citizenshipCode = ''
      if (citizenshipList.length && citizenship >= 0) {
        citizenshipCode = citizenshipList[citizenship].code
      }

      let passengerInfo
      if (values.personalIdentificationNumber) {
        passengerInfo = {
          comments: optionalInfo,
          citizenship: citizenshipCode,
          seqN,
        }
      } else {
        passengerInfo = {
          birthday: birthDayISO,
          citizenship: citizenshipCode,
          comments: optionalInfo,
          firstName,
          gender,
          lastName,
          seqN,
        }
      }
      try {
        const updatedReservation = yield call(
          reservationModificationPassengerEditService,
          reservationId,
          passengerInfo,
          seqN
        )
        const updateReservationWithoutFee = removeFeeItemsFromReservation(updatedReservation)

        if (!isMaritime) {
          yield put({
            type: SELECT_PASSENGER_TO_EDIT_PERSONAL_DATA,
            payload: getNextGuestSeqNum(seqN, updateReservationWithoutFee.guests),
          })
        }

        yield put({
          type: REMOVE_ONE_PASSENGER_FORM_DATA,
          payload: { seqNum: seqN },
        })

        yield put({
          type: Actions.RESERVATION_MODIFIED,
          payload: updateReservationWithoutFee,
        })
      } catch (e) {
        yield put({
          type: Actions.RESERVATION_MODIFY_FAILED,
          payload: { message: 'Unable to add passenger data' },
        })
      }
    }
  }
}

const createNewLocalIdField = () => ({ localID: '', status: 'not-checked' })

export function* changeLocalTravellersInForm({ payload }) {
  const localTravellersIDFormValues = yield select(getLocalTravellerFormValues)

  if (localTravellersIDFormValues) {
    const localIDsInForm = localTravellersIDFormValues.localIDs || []
    let localIDs = []

    if (payload.count > localIDsInForm.length) {
      const count = payload.count - localIDsInForm.length
      const fields = Array(count).fill(null).map(createNewLocalIdField)
      localIDs = localIDsInForm.concat(fields)
    } else {
      const removal = localIDsInForm[localIDsInForm.length - 1] || {}
      localIDs = localIDsInForm.slice(0, payload.count)

      if (removal.status === 'exist') {
        yield put(
          changeItemQtty({
            count: 0,
            initialCount: 0,
            localID: removal.localID,
            index: localIDsInForm.length,
            delta: 1,
          })
        )
      }
    }

    if (localIDs.length === 0) {
      yield put(clearFields('ticketsForm', false, false, 'LOCAL_TRAVELLER'))
      return
    }

    yield put(change('localTravellerIDForm', 'localIDs', localIDs))
  }
  const modal = yield select(pathOr('', ['modal']))
  if (!modal) {
    yield put(change('ticketsForm', payload.code, payload))
  }
  if (!payload.resident && payload.code) {
    yield put(change('ticketsForm', payload.code, payload))
  }
}

const objFromItems = compose(
  reduce(
    (result, { priceCategory, quantity }) => ({
      ...result,
      [priceCategory]: quantity,
    }),
    {}
  ),
  map(pick(['priceCategory', 'quantity']))
)

function* selectActualWithDeltaData() {
  const editableTrip = yield select(getEditableTrip)
  const { seqN: sailSeqNum } = editableTrip || {}

  const cachedSeats = yield select(pathOr({}, ['userSelections', 'seats']))
  const rawItems = yield select(pathOr([], ['reservation', 'current', 'items']))
  const items = reject(hasIn('promotion'))(rawItems)
  const obj = objFromItems(
    items.filter(({ sailPackageSeqN }) => {
      if (sailSeqNum) {
        return sailSeqNum === sailPackageSeqN
      }

      return true
    })
  )
  const seatWithBackupScenario = Object.values(cachedSeats).find((seat) => {
    return (
      seat.customVehicleParams &&
      seat.customVehicleParams.companyRegistrationNumber &&
      seat.customVehicleParams.backupRoadAdministrationRegistryUsed &&
      seat.customVehicleParams.backupBusinessRegistryUsed
    )
  })
  if (seatWithBackupScenario && seatWithBackupScenario.relatedDiscountCode) {
    cachedSeats[seatWithBackupScenario.relatedDiscountCode] = {
      ...seatWithBackupScenario,
      code: seatWithBackupScenario.relatedDiscountCode,
      customVehicleParams: {
        ...seatWithBackupScenario.customVehicleParams,
      },
    }
    delete cachedSeats[seatWithBackupScenario.code]
  }

  return mapObjIndexed((val, key) => ({
    ...val,
    count: val.count - propOr(0, key)(obj),
  }))(cachedSeats)
}

const getCorrectTicket = (ticketsFormValues, item) => {
  const key = Object.keys(ticketsFormValues).find((key) => {
    const { localID, code, personalIdentificationNumbers = [] } = ticketsFormValues[key]
    if (!localID && code && code !== 'LOCAL_TRAVELLER') {
      return contains(item.localID, personalIdentificationNumbers)
    }
    return item.localID === localID
  })
  if (key) {
    return ticketsFormValues[key]
  }
  return null
}

function* removeTicketOfLastLocalID() {
  const localTravellersIDFormValues = yield select(getLocalTravellerFormValues)

  if (localTravellersIDFormValues) {
    const localIDsInForm = localTravellersIDFormValues.localIDs || []
    const ticketFormValues = yield select(getTicketsFormValues)
    const removalTicket = yield call(getCorrectTicket, ticketFormValues, localIDsInForm[localIDsInForm.length - 1])
    if (removalTicket) {
      yield put(
        changeItemQtty({
          ...removalTicket,
          count: removalTicket.count - 1,
          type: 'confirm',
        })
      )
    } else {
      yield put(clearFields('ticketsForm', false, false, 'LOCAL_TRAVELLER'))
    }
  }
}

export function* handleResidentDiscountPromotion({ payload }) {
  const { newCategory, residentID, checked, backupRegistryUsed } = payload

  const removalObject = {
    [`ISIK_${residentID}`]: {
      localID: residentID,
      count: 0,
    },
  }

  const promotionObject = {
    [`ISIK_P_${residentID}`]: {
      localID: residentID,
      count: 1,
      backupRegistryUsed,
      ...(checked && { code: newCategory }),
    },
  }

  const request = yield call(updateMultiCatsReservationGenerator, { ...removalObject, ...promotionObject })
  yield put(
    modifyReservation({
      request,
    })
  )
}

export function* handleSeats({ payload = {} }) {
  const sailPackages = yield select(getSelectedSailPackages)
  const selectedSails = yield select(getSelectedSailRefIds)
  const modal = yield select(pathOr('', ['modal']))
  const areTicketsBeingEditingNow = modal === 'tickets'
  const editModeButNotCreateCase = areTicketsBeingEditingNow

  if (sailPackages.length !== keys(selectedSails).length && !editModeButNotCreateCase) {
    console.error('selected sailPackages and selected sails mismatch, cannot proceed', payload)
    yield put({
      type: Actions.RESERVATION_CREATE_FAILED,
      payload: { message: 'Please go back and select the reverse direction', newFormValues: { ...payload, count: 0 } },
    })

    return
  }

  const { code, type = '', resident = false, formType = '' } = payload

  if (code === 'LOCAL_TRAVELLER') {
    yield put(changeLocalTravellersQuantity(payload))
    return
  }
  if (areTicketsBeingEditingNow) {
    if (!code && !resident) {
      if (payload.count === 0) {
        yield call(removeTicketOfLastLocalID)
        return
      }
      return
    }
    if (code && resident && type !== 'confirm') {
      yield put(changeLocalTravellersQuantity(payload))
      return
    }
  }

  yield put(startPriceCalculation())

  const typesWithoutDelay = ['dropdown', 'check', 'confirm', 'vehicle-close-icon']
  if (!areTicketsBeingEditingNow && typesWithoutDelay.indexOf(type) === -1) {
    yield delay(1000)
  }

  if (type === 'vehicle-close-icon') {
    if (formType.toLowerCase().startsWith('vehicle')) {
      yield put(reset('vehiclesForm'))
    } else {
      yield put(reset('trailersForm'))
    }
  }

  yield put(addSeat(payload))

  const currentReservation = yield select(getCurrentReservation)
  const actualDataWithDeltas = yield call(selectActualWithDeltaData)
  const actualData = yield select(pathOr({}, ['userSelections', 'seats']))
  const { roundTrip, charter } = payload
  const isRoundTripCharter = roundTrip && charter

  if (!currentReservation) {
    const request = isRoundTripCharter
      ? yield call(createReservationWithCharterTicket, actualDataWithDeltas)
      : yield call(createReservationForManyGenerator, actualDataWithDeltas)
    if (charter) {
      yield put(setIsChaterAdding(true))
    }
    yield put(
      createReservation({
        request,
        ...payload,
        actualData,
      })
    )
  } else {
    let request
    const ticketType = Object.keys(actualData)[0]
    const ticket = actualData[ticketType]
    const isVehicle = ticket.subType === 'VEHICLE'

    if (isMaritime && isVehicle) {
      const actualDataWithDeltasTicket = actualDataWithDeltas[ticketType]
      request = yield call(
        updateMultiCatsReservationGenerator,
        ticket.count === 1
          ? actualData
          : actualDataWithDeltasTicket.count < -1
          ? { [actualDataWithDeltasTicket.code]: { ...actualDataWithDeltasTicket, count: -1 } }
          : actualDataWithDeltas
      )
    } else {
      request = isRoundTripCharter
        ? yield call(updateReservationWithCharterTicket, actualDataWithDeltas)
        : yield call(updateMultiCatsReservationGenerator, actualDataWithDeltas)

      if (charter && Object.values(actualDataWithDeltas)[0].count < 0) {
        yield put(setIsChaterAdding(false))
      } else if (charter) {
        yield put(setIsChaterAdding(true))
      }
    }

    yield put(
      modifyReservation({
        request,
        ...payload,
        actualData,
      })
    )
  }

  yield put(flushSeats())
}

// eslint-disable-next-line no-unused-vars
export function* handleTicketQuantityChange({ payload = {} }) {
  // const modal = yield select(pathOr('', ['modal']))
  // const areTicketsBeingEditingNow = modal === 'tickets'
  // const { count, code } = payload
  // const noReservation = yield select(isReservationEmpty)
  //
  // if (code === 'LOCAL_TRAVELLER') {
  //   yield put(changeLocalTravellersQuantity(payload))
  //   return
  // }
  //
  // if (!areTicketsBeingEditingNow) yield put(startFetchingReservation())
  //
  // if (noReservation && !areTicketsBeingEditingNow) {
  //   const request = yield call(createReservationGenerator, payload)
  //   yield put(createReservation({ request, ...payload }))
  // } else {
  //   const formValues = yield select(getTicketsFormValues)
  //   const countOrZero = pathOr(0, [code, 'count'])
  //
  //   const initialCount = countOrZero(formValues)
  //   let delta = count - initialCount
  //
  //   if (count === 0) {
  //     delta = 0
  //   }
  //
  //   const request = !areTicketsBeingEditingNow
  //     ? yield call(updateReservationGenerator, { ...payload, count: delta })
  //     : ''
  //   yield put(modifyReservation({ ...payload, delta, initialCount, request }))
  // }
}

export function* handleDriverDetailsSubmit({ payload }) {
  yield console.log('driver details submitted, ', payload)
}

function* reservationCreateOrModifyError({ payload: { newFormValues = {} } = {} }) {
  const { inventoryClass, subType = 'PASSENGER', localID } = newFormValues

  yield put(flushSeats())

  if (localID) {
    const { index } = newFormValues
    const localTravellersIDFormValues = yield select(getLocalTravellerFormValues)
    const localIDsInForm = localTravellersIDFormValues.localIDs || []

    const setFieldContentToNotFound = change.bind(
      null,
      'localTravellerIDForm',
      'localIDs',
      update(
        index,
        {
          localID: `${warningSign} ${localID}`,
          status: 'not-exist',
        },
        localIDsInForm
      )
    )

    yield put(setFieldContentToNotFound())
    return
  }

  if (subType === 'VEHICLE') {
    yield put(clearFields('ticketsForm', false, false, newFormValues.code))
    return
  }

  if (inventoryClass === 'CAR' && subType === 'DEFAULT') {
    yield put(clearFields('vehiclesForm', false, false, 'vehicleManuallyTicket'))
    return
  }

  if (inventoryClass === 'TRAILER' && subType === 'TRAILER') {
    yield put(clearFields('trailersForm', false, false, 'trailerManuallyTicket'))
    return
  }

  const items = yield select(pathOr([], ['reservation', 'current', 'items']))
  const realCount = compose(propOr(0, 'quantity'), find(propEq('priceCategory', newFormValues.code)))(items)

  if (newFormValues && newFormValues.code) {
    yield put(
      change('ticketsForm', newFormValues.code, {
        ...newFormValues,
        count: realCount,
      })
    )
  }
}

function* reservationCreateOrModifySuccess({ newFormValues = {}, payload = {} }) {
  const editableTrip = yield select(getEditableTrip)
  const { seqN: sailSeqNum } = editableTrip || {}
  const trailerPriceCategoryFromPayload = compose(
    propOr('', 'priceCategory'),
    find(propEq('priceCategorySubType', 'TRAILER'))
  )

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < keys(newFormValues).length; i++) {
    const key = keys(newFormValues)[i]
    const { count, code, subType, plateNumber } = newFormValues[key]
    if (key.startsWith('ISIK_')) {
      // const localID = key.replace('ISIK_', '')

      // const localIDsInForm = localTravellersIDFormValues.localIDs || []
      // const setFieldStatusOk = change.bind(null, 'localTravellerIDForm', 'localIDs', update(index, {
      //   localID,
      //   status: 'exist',
      // }, localIDsInForm))
      //
      // const setFieldStatusNotChecked = change.bind(null, 'localTravellerIDForm', 'localIDs', update(index, {
      //   localID,
      //   status: 'not-checked',
      // }, localIDsInForm))
      //
      // if (count === 0) {
      //   yield put(setFieldStatusNotChecked())
      // } else {
      //   yield put(setFieldStatusOk())
      // }

      yield put(flushSeats())
    } else if (count === 0) {
      if (subType === 'VEHICLE') {
        yield put(clearFields('ticketsForm', false, false, plateNumber))
      } else {
        yield put(clearFields('ticketsForm', false, false, code))
      }
    } else {
      const items = yield select(pathOr([], ['reservation', 'current', 'items']))

      const realCount = compose(
        propOr(0, 'quantity'),
        find(({ priceCategory, sailPackageSeqN }) => {
          if (sailSeqNum) {
            return sailSeqNum === sailPackageSeqN && priceCategory === code
          }

          return priceCategory === code
        })
      )(items)

      if (isMaritime && code && subType === 'VEHICLE') {
        yield put(
          change('ticketsForm', plateNumber, {
            ...newFormValues[key],
            count,
          })
        )
      } else if (code && key !== 'type') {
        yield put(
          change('ticketsForm', code, {
            ...newFormValues[key],
            count: realCount,
          })
        )
      }
    }

    const trailerPriceCategory = trailerPriceCategoryFromPayload((payload && payload.items) || [])
    // BFE-571 hack to replace the old priceCategory with the one came form reservation
    if (trailerPriceCategory) {
      yield put(change('trailersForm', 'vehicleTicket.code', trailerPriceCategory))
      yield put(change('trailersForm', 'vehicleTicket.priceCategory', trailerPriceCategory))
      yield put(change('trailersForm', 'plateNumberVehicle.code', trailerPriceCategory))
      yield put(change('trailersForm', 'plateNumberVehicle.priceCategory', trailerPriceCategory))
    }
  }

  const items = (payload && payload.items) || []
  const currentLegItems = sailSeqNum ? items.filter((item) => item.sailPackageSeqN === sailSeqNum) : items
  yield put(updateVehicle(currentLegItems))

  if (isBluewave) {
    yield call(writeDriverDetailsIfNeeded)
  }

  const currentReservation = yield select(getCurrentReservation)
  const reservationOwner = pathOr(null, ['reservationOwner'], currentReservation)
  const lastRequest = yield select(getLastRequest)
  const currentFormFields = yield select(pathOr({}, ['form', 'ticketsForm', 'values']))
  const formValuesWithDeckCode = compose(
    map(prop('code')),
    filter(propEq('subType', 'DECK')),
    filter(is(Object)),
    values
  )
  let itemsToRemoveFromForm = null
  if (formValuesWithDeckCode(currentFormFields).length) {
    itemsToRemoveFromForm = formValuesWithDeckCode(currentFormFields).find(
      (priceCategory) => !currentLegItems.map((it) => it.priceCategory).includes(priceCategory)
    )
  }
  if (!currentReservation.guests || !currentReservation.guests.length) {
    // todo: you need to find the price category which exists in the form but not in the reservation
    // todo: and remove it OR decrease the count
    const findDeckCode = compose(prop('code'), head, filter(propEq('subType', 'DECK')), filter(is(Object)), values)

    const codeToRemove = findDeckCode(currentFormFields)
    if (codeToRemove) {
      yield put(clearFields('ticketsForm', false, false, codeToRemove))
    }
  } else if (itemsToRemoveFromForm) {
    yield put(clearFields('ticketsForm', false, false, itemsToRemoveFromForm))
  }

  if (currentReservation && !lastRequest.endsWith('extend')) {
    const { guests = [] } = currentReservation
    if (guests.length === 0) {
      yield put({ type: CLEAR_PASSENGER_SEQ_NUM })
    } else if (guests.length === 1) {
      yield put({
        type: SELECT_PASSENGER_TO_EDIT_PERSONAL_DATA,
        payload: compose(prop('seqN'), head)(guests),
      })
    }
  }

  if (!reservationOwner && !isMaritime) {
    yield put(setReservationCustomerInfo())
  }
}

// eslint-disable-next-line
function* moveToPaymentProvider({ payload: redirectUrl }) {
  yield window.location.assign(redirectUrl)
}

function* updateCalculatedVehiclePrice({ payload: { formName, price } }) {
  yield put(change(formName, 'calculatedTicketObj', price))
}

export function* watchCalculateVehiclePrice() {
  yield takeEvery(CALCULATE_VEHICLE_PRICE_SUCCESS, updateCalculatedVehiclePrice)
}

export function* watchCreateReservation() {
  yield takeEvery('inventory/RESERVATION_CREATED', showAlert)
  yield takeEvery(RESERVATION_CREATED, showAlert)
}

export function* watchCreateReservationFailed() {
  yield takeEvery(RESERVATION_CREATE_FAILED, showAlert)
}

export function* watchSettingTransactionStatus() {
  yield takeEvery(SEND_TRANSACTION_STATUS_SUCCESS, updateReservationPaymentStatus)
}

// export function* watchChangeItemQtty() {
//   yield throttle(3000, changeItemQtty, handleTicketQuantityChange)
// }

export function* anotherWatchChangeItemQtty() {
  yield takeLatest(changeItemQtty, handleSeats)
}

export function* watchResidentDiscountPromotion() {
  yield takeLatest(promoteResidentDiscount, handleResidentDiscountPromotion)
}

export function* watchChangeLocalTravellersQuantity() {
  yield takeLatest(changeLocalTravellersQuantity, changeLocalTravellersInForm)
}

export function* watchManageUrlReservation() {
  yield takeEvery(MANAGE_URL_RESERVATION, updateReservationByUrl)
}

export function* watchGetDataForReservationUpdate() {
  yield takeEvery(getDataForReservationUpdate, fetchDataForReservationUpdate)
}

export function* watchReservationConfirmed() {
  yield takeEvery(RESERVATION_CONFIRM_SUCCESS, handleReservationConfirm)
}

export function* watchSuccessReservationCreateOrModify() {
  yield takeEvery([RESERVATION_CREATED, RESERVATION_MODIFIED], reservationCreateOrModifySuccess)
}

export function* watchFailReservationCreateOrModify() {
  yield takeEvery([RESERVATION_CREATE_FAILED, RESERVATION_MODIFY_FAILED], reservationCreateOrModifyError)
}

export function* watchImmediatelyPayment() {
  yield takeEvery(IMMEDIATELY_PAYMENT_PAYED, goToTickets)
}

export function* watchReservationCustomerChange() {
  yield takeEvery([selectCustomerRole, setReservationCustomerInfo], modifyReservationOwner)
}

export function* watchReservationPassengerChange() {
  yield takeEvery([setReservationPassengerInfo], modifyPassengerInfo)
}

export function* watchGettingReservationParts() {
  yield takeEvery(GET_RESERVATION_PARTS_SUCCESS, setReservationsParts)
}

export function* watchReservationExpired() {
  yield takeEvery(RESERVATION_EXPIRED, clearCurrentReservation)
}

export function* watchPaymentReplaceLocation() {
  yield takeEvery(paymentReplaceLocation, moveToPaymentProvider)
}

export function* watchRefundSuccessLocation() {
  yield takeEvery(refundSuccess, goToRefundSuccess)
}

export function* watchSubmitDriverDetails() {
  yield takeEvery([submitDriverDetails], handleDriverDetailsSubmit)
}

export default function* reservation() {
  yield [
    watchCalculateVehiclePrice(),

    watchCreateReservation(),
    watchCreateReservationFailed(),
    watchSuccessReservationCreateOrModify(),
    watchFailReservationCreateOrModify(),

    watchReservationExpired(),
    watchImmediatelyPayment(),
    watchReservationConfirmed(),
    watchSettingTransactionStatus(),

    watchReservationCustomerChange(),
    watchReservationPassengerChange(),
    watchChangeLocalTravellersQuantity(),

    watchManageUrlReservation(),
    watchGettingReservationParts(),
    watchGetDataForReservationUpdate(),

    watchPaymentReplaceLocation(),
    watchRefundSuccessLocation(),
    anotherWatchChangeItemQtty(),
    watchUpdateVehicle(),
    watchModifyReservation(),
    watchResidentDiscountPromotion(),
    watchSubmitDriverDetails(),
  ]
}

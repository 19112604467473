import axios from 'axios'

const { idPortal } = window.brandProps

export const signin = ({ source, ...data }) =>
  axios({
    method: 'post',
    url: `/api/auth/signin/${source}`,
    data,
  })

export const checkStatus = ({ source, ...data }) =>
  axios({
    method: 'post',
    url: `/api/auth/check-status/${source}`,
    data,
  })

export const signup = ({ source, ...data }) =>
  axios({
    method: 'post',
    url: `/api/auth/signup/${source}`,
    data,
  })

export const signout = () =>
  axios({
    method: 'post',
    url: '/api/auth/logout',
  })

export const recover = (data) =>
  axios({
    method: 'post',
    url: '/api/auth/recover',
    data,
  })

export const resetPassword = (data) => {
  axios({
    method: 'post',
    url: 'api/auth/resetPassword',
    data,
  })
}

export const activate = (hash) =>
  axios({
    method: 'post',
    url: `/api/auth/activate/${hash}`,
  })

export const validateJoinCompanyLogin = ({ joinToken, personalId, source, Certificate }) =>
  axios({
    method: 'put',
    url: '/api/company/invitation/login',
    data: {
      token: joinToken,
      personalIdentificationNumber: personalId,
      source,
      Certificate,
    },
  })

export const idCartCertificate = async () => {
  // Using common fetch here not to take axios config
  // Not to impact this single request
  try {
    const response = await fetch(idPortal)
    return await response.json()
  } catch (e) {
    console.error('fetch error', e)
    throw new Error(`Cannot fetch ${idPortal}`)
  }
}

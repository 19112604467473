import { call, put, takeEvery, select } from 'redux-saga/effects'
import { fetchPackageInventory } from './api/inventory'
import { getSailDate } from '../user-selections'
import {
  FETCH_ROUTE_LEG_INVENTORY,
  INVENTORY_FETCH_SUCCESS,
  INVENTORY_FETCH_FAIL,
  ALL_INVENTORY_FETCH_SUCCESS,
  CHANGE_INVENTORY_DATE,
  CHANGE_INVENTORY_LEG,
  SW_INVENTORY_FETCH_SUCCESS,
  SET_ALL_INVENTORY_FETCH_SUCCESS,
} from './consts'
import {
  getAvailabilitiesWithDepartureTimeLessThanTargetTime,
  getAvailabilitiesWithDepartureTimeNotLessThanTargetTime,
  sailsInventoriesMutator,
} from './utils'
import { delay } from 'redux-saga'
import moment from 'moment'
import { makeSafeRequest } from '../../utils/makeSafeRequest'

function* fetchInventorySaga({ payload: { spCode, date = '', time = '' } }) {
  let inventoryDate

  if (date) {
    inventoryDate = date
  } else {
    inventoryDate = yield select(getSailDate)
  }

  try {
    const data = yield call(fetchPackageInventory, { spCode, date: inventoryDate, time })

    const nextDayInventoryDate = moment(inventoryDate).add(1, 'days').format('YYYY-MM-DD')
    const { data: nextDayData = {} } = yield call(makeSafeRequest, () =>
      fetchPackageInventory({ spCode, date: nextDayInventoryDate, time })
    )

    const currentAndNextDayData = data && {
      ...data,
      availabilities: {
        ...getAvailabilitiesWithDepartureTimeNotLessThanTargetTime(data),
        ...getAvailabilitiesWithDepartureTimeLessThanTargetTime(nextDayData),
      },
    }

    yield put({
      type: INVENTORY_FETCH_SUCCESS,
      payload: currentAndNextDayData,
    })

    if (!time && currentAndNextDayData) {
      const inventories = sailsInventoriesMutator(currentAndNextDayData)

      yield delay(600)
      yield put({
        type: ALL_INVENTORY_FETCH_SUCCESS,
        payload: {
          inventories,
          sailPackageCode: spCode,
        },
        currentAndNextDayData,
      })
    }
  } catch (e) {
    yield put({
      type: INVENTORY_FETCH_FAIL,
    })
  }
}

function* setAllInventories({ payload: data }) {
  const inventories = sailsInventoriesMutator(data)

  yield put({
    type: SET_ALL_INVENTORY_FETCH_SUCCESS,
    payload: {
      inventories,
      sailPackageCode: data.routeLeg,
    },
    data,
  })
}

function* getInventory() {
  yield takeEvery(FETCH_ROUTE_LEG_INVENTORY, fetchInventorySaga)
  yield takeEvery(CHANGE_INVENTORY_DATE, fetchInventorySaga)
  yield takeEvery(CHANGE_INVENTORY_LEG, fetchInventorySaga)
}

function* getInventoryFromSw() {
  yield takeEvery(SW_INVENTORY_FETCH_SUCCESS, setAllInventories)
}

export function* inventorySaga() {
  yield [getInventory(), getInventoryFromSw()]
}

import messages from '../consts/messages'

export const getVehicleDimensions = (vehicleCode = 'CAR') => {
  const { vehicleTypes = [] } = window.brandProps.backendConfig
  const vehicleConfig = vehicleTypes.find((vehicleType) => vehicleType.code === vehicleCode) || {}

  vehicleConfig.lengthInCm = 1000
  vehicleConfig.maxLengthInCm = 3000
  vehicleConfig.weightInKg = 21500
  vehicleConfig.heightInCm = 4000
  vehicleConfig.widthInCm = 2600

  return {
    lengthRange: {
      MIN: { title: `${vehicleConfig.lengthInCm / 100}m`, value: vehicleConfig.lengthInCm },
      MAX: { title: `${(vehicleConfig.maxLengthInCm || 3000) / 100}m`, value: vehicleConfig.maxLengthInCm || 3000 },
    },
    weightRange: {
      MIN: { title: '21.5t', value: vehicleConfig.weightInKg },
      MAX: { title: '39t', value: 70000 },
    },
    height: [{ title: 'less than 4m', translation: messages.carHeightS, value: vehicleConfig.heightInCm }],
    width: [{ title: 'less than 2.6m', translation: messages.carWidthS, value: vehicleConfig.widthInCm }],
    default: {
      widthInCm: vehicleConfig.widthInCm,
      heightInCm: vehicleConfig.heightInCm,
      lengthInCm: vehicleConfig.lengthInCm,
      weightInKg: vehicleConfig.weightInKg,
    },
  }
}

export const getVehicleDimensionsBluewave = (vehicleParams = {}) => {
  return {
    height: [{ title: 'less than 4m', translation: messages.carHeightS, value: vehicleParams.heightInCm }],
    width: [{ title: 'less than 2.6m', translation: messages.carWidthS, value: vehicleParams.widthInCm }],
    default: {
      widthInCm: vehicleParams.widthInCm,
      heightInCm: vehicleParams.heightInCm,
      lengthInCm: vehicleParams.lengthInCm,
      weightInKg: vehicleParams.weightInKg,
    },
  }
}

import { createSelector } from 'reselect'
import moment from 'moment'
import {
  compose,
  curry,
  defaultTo,
  filter,
  find,
  flatten,
  head,
  identity,
  invertObj,
  isEmpty,
  keys,
  map,
  mapObjIndexed,
  or,
  path,
  pathEq,
  pathOr,
  pick,
  prop,
  propEq,
  propOr,
  reduce,
  sortBy,
  values,
} from 'ramda'

import { getSailDates, getSelectedSailPackages } from '../../user-selections/selectors'
import { notNil } from '../../../utils/ramda-extend'

const isCancelled = propEq('status', 'CANCELLED')
const cancelledSailRefIds = compose(map(prop('sailRefId')), filter(isCancelled), flatten, values)

const computeDepartureTimesModel = curry((code, inventories) => {
  const data = pathOr([], [code], inventories)

  return data
    .map((item) => ({
      available: null,
      sail: item.sail,
      from: item.sail.departure.time,
      to: item.sail.arrival.time,
      count: (item.inventory.inventories.availableInventoryClasses || [])
        .filter((item) => item.inventoryClass !== 'REEFER_SOCKER')
        .reduce(
          (amount, inventory) => amount + inventory.amount + (inventory.reserves ? inventory.reserves[0].amount : 0),
          0
        ),
      vehicleAvailability: (item.inventory.inventories.availableInventoryClasses || [])
        .filter((item) => item.subType === 'VEHICLE')
        .reduce(
          (amount, inventory) => amount + inventory.amount + (inventory.reserves ? inventory.reserves[0].amount : 0),
          0
        ),
      inventoryEnabled: pathOr(false, ['inventory', 'inventories', 'enabled'], item),
    }))
    .filter((item) => item.count > 0 && item.inventoryEnabled)
})

const combineSailsWithInventories = (inventoryList, sailsList) => {
  if (isEmpty(sailsList) || isEmpty(inventoryList)) return []

  // Commented out since inventory is found from sailRefId, not from route leg and time (hUgHn5lJ)
  // const hasSameRouteLeg = ({ routeLeg: sail }) => ({ routeLeg }) => sail === routeLeg
  // const hasSameDepartureTime = ({ departure }) => ({ inventories }) =>
  //   inventories.departureTime.slice(0, 5) === departure.time
  const hasSameSailRefId =
    ({ sailRefId }) =>
    ({ inventories }) => {
      return inventories.sailRefId === sailRefId
    }
  // const inventoryForSail = compose(and(hasSameRouteLeg, hasSameDepartureTime))

  const sailInventory = (sail) => {
    const inventoriesInSameSailPackage = pathOr([], [sail.sailPackage], inventoryList)
    const matchedInventory = find(hasSameSailRefId(sail))(inventoriesInSameSailPackage)

    return {
      sail,
      inventory: or(matchedInventory, { inventories: {} }),
    }
  }

  const combinedSailInventories = mapObjIndexed(map(sailInventory), sailsList)

  return combinedSailInventories
}

export const selectScheduleState = prop('schedule')
export const sails = pathOr([], ['schedule', 'sails'])
export const selectIsRequestingDates = createSelector(selectScheduleState, propOr(false, 'requestingDates'))
export const inventories = pathOr([], ['schedule', 'inventories'])
export const selectedSailsDefault = pathOr([], ['schedule', 'sails'])
export const selectedSails = pathOr([], ['schedule', 'selectedSails'])
export const selectedSailsWhileEdit = pathOr([], ['schedule', 'selectedSailsWhileEdit'])
export const selectedSailPackagesWhileEdit = pathOr([], ['reservation', 'edit', 'reservation', 'sailPackages'])
export const getSelectedSailRefIdList = createSelector(selectedSails, values)
export const getSelectedSailCodes = createSelector(selectedSails, keys)
export const getSelectedSailRefIds = createSelector(selectedSails, identity)
export const getSelectedSailCodeByRefId = (refId) => createSelector(selectedSails, compose(prop(refId), invertObj))
export const getFirstAvailableSail = (sails) => {
  const sortedSails = sails.sort((sailA, sailB) => {
    if (sailA.departure && sailA.departure.timestamp && sailB.departure && sailB.departure.timestamp) {
      return new Date(sailA.departure.timestamp) - new Date(sailB.departure.timestamp)
    }
    return 1
  })
  return sortedSails.find((sail) => {
    return (
      sail.status === 'OPEN' &&
      sail.departure &&
      sail.departure.timestamp &&
      new Date(sail.departure.timestamp) > new Date()
    )
  })
}

export const isLaaksaareInSelectedSails = createSelector(
  getSelectedSailCodes,
  compose(
    notNil,
    find((code) => code.includes('LAA'))
  )
)

export const sailsBySailPackageCode = (state) => (code) => createSelector(sails, prop(code))(state)
export const selectedSailBySailPackageCode = (state) => (code) => {
  const selectedSailDefault =
    selectedSailsDefault(state)[code] && getFirstAvailableSail(selectedSailsDefault(state)[code])
  const sailRefId = or(selectedSails(state)[code], selectedSailDefault && selectedSailDefault.sailRefId)
  const sailsInSailPackage = or(sailsBySailPackageCode(state)(code), [])
  let result = find(propEq('sailRefId', sailRefId), sailsInSailPackage)
  if (!result && selectedSailDefault && selectedSailDefault.sailRefId) {
    result = find(propEq('sailRefId', selectedSailDefault.sailRefId), sailsInSailPackage)
  }
  return result
}

export const selectedSailBySailPackageCodeWhileEdit = (state) => (code, isCancelled) => {
  const sailRefIdFromSchedule = or(selectedSailsWhileEdit(state)[code], '')
  const sailRefIdFromReservationEdition = or(
    selectedSailPackagesWhileEdit(state)[state.selectedSailPackageIndexToEdit].sailRefs[0].sailRefId,
    ''
  )
  const sailRefId = isCancelled || !sailRefIdFromSchedule ? sailRefIdFromReservationEdition : sailRefIdFromSchedule
  const sailsInSailPackage = or(sailsBySailPackageCode(state)(code), [])
  let result = find(propEq('sailRefId', sailRefId), sailsInSailPackage)
  if (!result) {
    result = sailsInSailPackage && sailsInSailPackage[0]
  }
  return result
}

export const getAvailableReplacementLegs = createSelector(selectScheduleState, propOr([], 'availableReplacementLegs'))
export const getSailsAvailableDatesState = createSelector(selectScheduleState, prop('availableDates'))
export const getScheduleDate = createSelector(selectScheduleState, prop('date'))
export const sailInventories = createSelector(inventories, sails, combineSailsWithInventories)
export const sailInventoriesBySailPackageCode = (code) => createSelector(sailInventories, prop(code))

const filterSailInventoriesBySailRefId = (sailRefId) =>
  compose(
    flatten,
    map(pathOr([], ['inventory', 'inventories', 'availableInventoryClasses'])),
    filter(pathEq(['sail', 'sailRefId'], sailRefId)),
    defaultTo([])
  )
const filterSailInventoriesVesselFeaturesBySailRefId = (sailRefId) =>
  compose(
    flatten,
    map(pathOr([], ['inventory', 'inventories', 'vesselFeatures'])),
    filter(pathEq(['sail', 'sailRefId'], sailRefId)),
    defaultTo([])
  )

const codeFromProps = (state, props) => prop('code', props)
const sailRefIdFromProps = (state, props) => prop('sailRefId', props)
export const availableInventoriesClassesBySailPackageCodeAndSailRefId = () =>
  createSelector([codeFromProps, sailRefIdFromProps, sailInventories], (code, sailRefId, selectedSailInventories) =>
    compose(filterSailInventoriesBySailRefId(sailRefId), prop(code))(selectedSailInventories)
  )
export const vesselFeaturesBySailPackageCodeAndSailRefId = () =>
  createSelector([codeFromProps, sailRefIdFromProps, sailInventories], (code, sailRefId, selectedSailInventories) =>
    compose(filterSailInventoriesVesselFeaturesBySailRefId(sailRefId), prop(code))(selectedSailInventories)
  )

export const computedDepartureTimesModel = (code) => createSelector(sailInventories, computeDepartureTimesModel(code))

export const getNearestOrFirstSail = (code, selectedPackages) =>
  createSelector(
    computedDepartureTimesModel(code),
    selectedSailBySailPackageCode,
    (computedInventories, getSelectedSails) => {
      const getDepartureTimestamp = path(['sail', 'departure', 'timestamp'])
      const sortedInventories = sortBy(getDepartureTimestamp)(computedInventories)

      const firstSail = getSelectedSails(head(selectedPackages).code)
      const firstSailArrivalTimestamp = path(['arrival', 'timestamp'])(firstSail)

      const getNearestSail = (item) => moment(firstSailArrivalTimestamp).isBefore(moment(getDepartureTimestamp(item)))
      const nearestSail = find(getNearestSail)(sortedInventories)
      return selectedPackages.length === 2 && nearestSail ? nearestSail : head(sortedInventories)
    }
  )

const filteredDatesByFirstSelectedDate = (dates, selectedDates, selectedPackages) => {
  const [firstDate] = selectedDates
  const [, backPackage] = selectedPackages

  if (!firstDate || !backPackage) return dates

  const backPackageCode = backPackage.code
  const notEarlierThanTo = (date) => moment(date).isSameOrAfter(firstDate)

  const sailPackageDates = dates[backPackageCode]
  const correctDates = filter(notEarlierThanTo, sailPackageDates ? Object.keys(sailPackageDates) : [])

  return {
    ...dates,
    [backPackageCode]: pick(correctDates, sailPackageDates || {}),
  }
}

export const getSailsAvailableDates = createSelector(
  [getSailsAvailableDatesState, getSailDates, getSelectedSailPackages],
  filteredDatesByFirstSelectedDate
)

export const getCancelledSailRefsList = createSelector(sails, cancelledSailRefIds)

export const getSailDatesForSailRefIds = createSelector(sails, (obj = {}) => {
  const app = compose(
    reduce((acc, sail) => ({ ...acc, [sail.sailRefId]: sail.departure.date }), {}),
    flatten,
    values
  )

  try {
    return app(obj)
  } catch (e) {
    return {}
  }
})

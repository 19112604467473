/* eslint-disable no-plusplus */
export function IDGenerator() {
  this.length = 8
  this.timestamp = Number(new Date())

  const localGetRandomInt = (min, max) => Math.floor(Math.random() * (max - min + 1)) + min

  this.generate = function generate() {
    // eslint-disable-line
    const ts = this.timestamp.toString()
    const parts = ts.split('').reverse()
    let id = ''

    for (let i = 0; i < this.length; ++i) {
      const index = localGetRandomInt(0, parts.length - 1)
      id += parts[index]
    }

    return parseInt(id, 10)
  }
}

import { select } from 'redux-saga/effects'
import {
  map,
  compose,
  intersection,
  flatten,
  takeLast,
  flip,
  pathOr,
  assoc,
  filter,
  propEq,
  prop,
  has,
  equals,
  uniq,
  anyPass,
  contains,
  any,
  range,
  values,
  reduce,
  keys,
  head,
  find,
  ifElse,
  is,
  propOr,
  identity,
  pipe,
  always,
} from 'ramda'

import { notEmpty } from '../../utils/ramda-extend'
import { IDGenerator } from '../../utils/idGenerator'

import { selections, reservation as reservationService } from '../../services'
import { getSelectedSailRefIds } from '../../services/schedule/reducers/schedule'
import { isRoundTrip } from '../../services/user-selections'
import { getCurrentReservation } from '../../services/reservation/selectors'
import { isMaritime } from '../../utils/maritimeStyleUtils'

const getTicketType = (subType) => {
  switch (subType) {
    case 'CABIN':
      return 'cabin'
    case 'BERTH':
      return 'berth'
    default:
      return 'passenger'
  }
}

const firstKey = compose(head, keys)
const { getEditableTrip, getSelectedSailPackages } = selections

const parseCompanyRegistrationNumber = ifElse(is(Object), propOr('', 'companyRegistrationNumber'), identity)

const {
  getCurrentReservationId,
  getGuestNumbers,
  getItems,
  getGuests,
  getVehicleNumbers,
  getBicycleNumbers,
  getAddonNumbers,
  getCodeSeqNumsMap,
} = reservationService.selectors

const generator = new IDGenerator()

const uidsFromAmount = compose(map(generator.generate.bind(generator)), range(0))
const isBicycle = equals('BICYCLE')
const isVehicle = equals('VEHICLE')
const isDefaultVehicle = equals('DEFAULT')
const isTrailer = equals('TRAILER')
const isTransport = anyPass([isBicycle, isVehicle, isTrailer, isDefaultVehicle])

const transformForTickets =
  (passengerTypeObj, sails, seqN, spSeqNums = {}) =>
  ({ code: sailPackage }) => {
    if (!sails[sailPackage]) {
      console.error('sailRefIds empty', {
        sails,
        sailPackage,
      })
    }
    let vehicleObjectWithEditedWeight = {
      ...passengerTypeObj,
    }
    if (passengerTypeObj.vehicle && passengerTypeObj.vehicle.weightOnSails) {
      const relevantWeight = passengerTypeObj.vehicle.weightOnSails.find(
        (item) => item.sailRefId === sails[sailPackage]
      )
      vehicleObjectWithEditedWeight = {
        ...vehicleObjectWithEditedWeight,
        vehicle: {
          ...vehicleObjectWithEditedWeight.vehicle,
          weightOnSails: [relevantWeight],
        },
      }
      return {
        ...vehicleObjectWithEditedWeight,
        seqN,
        sailPackage,
        sailRefIds: [sails[sailPackage]],
        ...(notEmpty(spSeqNums) && { sailPackageSeqN: spSeqNums[sailPackage] }),
      }
    }
    return {
      ...passengerTypeObj,
      seqN,
      sailPackage,
      sailRefIds: [sails[sailPackage]],
      ...(notEmpty(spSeqNums) && { sailPackageSeqN: spSeqNums[sailPackage] }),
    }
  }
const transformForWeights = (weights, sails, sailPackages) => {
  return sailPackages.map((sailPackage, idx, array) => ({
    sailRefId: sails[sailPackage.code],
    weightInKg: weights.length < array.length ? weights[0] : weights[idx],
  }))
}

export function* updateReservationGenerator(payload) {
  const {
    code: priceCategory,
    count = 1,
    subType = 'PASSENGER',
    plateNumber = '',
    localID: personalIdentificationNumber,
    customVehicleParams = {},
    status,
    handicapped,
    companyRegistrationNumber,
    manuallyChangedWeights: weightInKg,
  } = payload
  const sailPackages = yield select(getSelectedSailPackages)
  const selectedSails = yield select(getSelectedSailRefIds)

  const reservationId = yield select(getCurrentReservationId)
  const sailPackagesSeqNums = yield select(getCodeSeqNumsMap)

  let amount = count
  let itemKey = 'item'

  let getUids = uidsFromAmount

  if (count < 0) {
    itemKey = 'purge-item'
    const seqNums = isBicycle(subType) ? yield select(getVehicleNumbers) : yield select(getGuestNumbers)
    amount = Math.abs(count)
    getUids = flip(takeLast)(seqNums)
  } else if (count === 0) {
    itemKey = 'purge-item'

    const items = yield select(getItems)
    const getOneSeqN = compose(uniq, flatten, map(prop('ownerSeqNs')))

    if (personalIdentificationNumber) {
      const guests = yield select(getGuests)
      const guestsSeqNs = compose(
        map(prop('seqN')),
        filter(propEq('personalIdentificationNumber', personalIdentificationNumber))
      )(guests)

      const guestsIncludeSeqN = compose(
        any((seqN) => contains(seqN, guestsSeqNs)),
        prop('ownerSeqNs')
      )
      const itemWithGuests = map((item) => assoc('ownerSeqNs', intersection(guestsSeqNs, item.ownerSeqNs), item))
      const getGuestNumsByLocalIds = compose(getOneSeqN, itemWithGuests, filter(guestsIncludeSeqN))

      getUids = () => getGuestNumsByLocalIds(items)
    } else {
      const getGuestNumsByCategory = compose(getOneSeqN, filter(propEq('priceCategory', priceCategory)))

      getUids = () => getGuestNumsByCategory(items)
    }
  }

  const requestsForTrip =
    (trip = {}) =>
    (requests) => {
      const notVehicleAndOfTripSailPackage = anyPass([
        pathOr(false, ['vehicle', 'licencePlate']),
        propEq('sailPackage', trip.code),
      ])

      return has('code', trip) ? filter(notVehicleAndOfTripSailPackage, requests) : requests
    }

  const transformSeqNums = (seqNum) => {
    let ticketType = {
      [getTicketType(subType)]: {
        priceCategory,
        personalIdentificationNumber,
      },
    }
    if (isTransport(subType)) {
      ticketType = {
        vehicle:
          status === 'verified'
            ? {
                licencePlate: plateNumber,
                ...(handicapped && { handicapped }),
                ...(companyRegistrationNumber && { companyRegistrationNumber }),
                ...(weightInKg && { weightOnSails: transformForWeights(weightInKg, selectedSails, sailPackages) }),
              }
            : {
                priceCategory,
                licencePlate: plateNumber,
                ...(handicapped && { handicapped }),
                ...(companyRegistrationNumber && { companyRegistrationNumber }),
                ...customVehicleParams,
              },
      }
    }
    const ticketsRequestsMaker = map(transformForTickets(ticketType, selectedSails, seqNum, sailPackagesSeqNums))
    return ticketsRequestsMaker(sailPackages)
  }

  const editableTrip = yield select(getEditableTrip)

  const allRequestsForAmount = compose(requestsForTrip(editableTrip), flatten, map(transformSeqNums), getUids)
  const request = {
    reservationId,
    [itemKey]: {
      requests: allRequestsForAmount(amount),
    },
  }

  return request
}

export function* createReservationGenerator(payload) {
  const {
    code: priceCategory,
    subType = 'PASSENGER',
    plateNumber = '',
    localID: personalIdentificationNumber,
    customVehicleParams = {},
    status,
    handicapped,
    companyRegistrationNumber,
    route,
    uiFormType,
    manuallyChangedWeights: weightInKg,
  } = payload

  const sailPackages = yield select(getSelectedSailPackages)
  const selectedSails = yield select(getSelectedSailRefIds)
  const seqNum = generator.generate()

  let ticketType = {
    [getTicketType(subType)]: { priceCategory, personalIdentificationNumber },
  }

  if (isTransport(subType)) {
    ticketType = {
      vehicle:
        status === 'verified'
          ? {
              licencePlate: plateNumber,
              ...(handicapped && { handicapped }),
              ...(companyRegistrationNumber && { companyRegistrationNumber }),
              ...(weightInKg && { weightOnSails: transformForWeights(weightInKg, selectedSails, sailPackages) }),
              ...(route && { route }),
              ...(uiFormType && { uiFormType }),
            }
          : {
              priceCategory,
              licencePlate: plateNumber,
              ...(handicapped && { handicapped }),
              ...(companyRegistrationNumber && { companyRegistrationNumber }),
              ...(route && { route }),
              ...(uiFormType && { uiFormType }),
              ...customVehicleParams,
            },
    }
  }

  const ticketsRequestsMaker = map(transformForTickets(ticketType, selectedSails, seqNum))

  const request = {
    saleChannel: window.brandProps.saleChannel,
    requests: ticketsRequestsMaker(sailPackages),
  }

  return request
}

export function* createReservationWithCharterTicket(seats) {
  const sailPackages = yield select(getSelectedSailPackages)
  const sailPackageCodeThere = sailPackages[0].code
  const sailPackageCodeBack = sailPackages[1].code
  const selectedSails = yield select(getSelectedSailRefIds)
  const sailRefIdThere = selectedSails[sailPackageCodeThere]
  const sailRefIdBack = selectedSails[sailPackageCodeBack]
  const roundTrip = yield select(isRoundTrip)
  const [priceCategory] = Object.keys(seats)
  const [payload] = Object.values(seats)
  const requests = []

  const createRequestItem = (priceCategory, sailPackageCode, sailRefId, seqN) => {
    return {
      passenger: {
        priceCategory,
      },
      sailPackage: sailPackageCode,
      sailRefIds: [sailRefId],
      seqN,
    }
  }

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < payload.countThere; i++) {
    requests.push(createRequestItem(priceCategory, sailPackageCodeThere, sailRefIdThere, generator.generate()))
  }

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < payload.countBack; i++) {
    requests.push(createRequestItem(priceCategory, sailPackageCodeBack, sailRefIdBack, generator.generate()))
  }

  return {
    saleChannel: window.brandProps.saleChannel,
    roundTrip,
    requests,
  }
}

export function* createReservationForManyGenerator(seats) {
  const sailPackages = yield select(getSelectedSailPackages)
  const selectedSails = yield select(getSelectedSailRefIds)
  const roundTrip = yield select(isRoundTrip)
  const allPayloads = compose(
    flatten,
    map(({ count, ...rest }) =>
      compose(
        map(() => rest),
        range(0)
      )(count)
    ),
    values
  )

  const categoriesToCreate = allPayloads(seats).map((payload) => {
    const {
      code: priceCategory,
      subType = 'PASSENGER',
      title,
      plateNumber = '',
      localID: personalIdentificationNumber,
      customVehicleParams = {},
      handicapped,
      companyRegistrationNumber: companyRegNumber,
      status,
      manuallyChangedWeights: weightInKg,
      isAddon = false,
      attributeCode = '',
    } = payload

    const companyRegistrationNumber = parseCompanyRegistrationNumber(companyRegNumber)

    const seqNum = generator.generate()

    let ticketType = {
      [getTicketType(subType)]: { priceCategory, personalIdentificationNumber },
    }

    if (isAddon) {
      if (attributeCode && title) {
        ticketType = {
          addon: {
            priceCategory,
            amount: 1,
            attributes: [
              {
                attribute: {
                  code: attributeCode,
                },
                structure: {
                  __structure: 'single',
                  value: {
                    __type: 'catalog',
                    value: title,
                  },
                },
              },
            ],
          },
        }
      } else {
        ticketType = {
          addon: {
            priceCategory,
            amount: 1,
          },
        }
      }
    }
    if (isTransport(subType) && !isAddon) {
      ticketType = {
        vehicle:
          status === 'verified'
            ? {
                licencePlate: plateNumber,
                ...(handicapped && { handicapped }),
                ...(companyRegistrationNumber && { companyRegistrationNumber }),
                ...(weightInKg && { weightOnSails: transformForWeights(weightInKg, selectedSails, sailPackages) }),
              }
            : {
                priceCategory,
                licencePlate: plateNumber,
                ...(handicapped && { handicapped }),
                ...(companyRegistrationNumber && { companyRegistrationNumber }),
                ...customVehicleParams,
              },
      }
    }

    const ticketsRequestsMaker = map(transformForTickets(ticketType, selectedSails, seqNum))
    return ticketsRequestsMaker(sailPackages)
  })

  return {
    saleChannel: window.brandProps.saleChannel,
    roundTrip,
    requests: flatten(categoriesToCreate),
  }
}

export function* updateReservationWithCharterTicket(seats) {
  const [payload] = Object.values(seats)
  const [priceCategory] = Object.keys(seats)
  const sailPackages = yield select(getSelectedSailPackages)
  const reservationId = yield select(getCurrentReservationId)
  const sailPackageCodeThere = sailPackages[0].code
  const sailPackageCodeBack = sailPackages[1].code
  const selectedSails = yield select(getSelectedSailRefIds)
  const sailRefIdThere = selectedSails[sailPackageCodeThere]
  const sailRefIdBack = selectedSails[sailPackageCodeBack]
  const sailPackagesSeqNums = yield select(getCodeSeqNumsMap)
  const roundTrip = yield select(isRoundTrip)
  const items = yield select(getItems) || []
  const requests = []

  const { count, countThere, countBack, backupRegistryUsed = false, localID: personalIdentificationNumber } = payload

  if (count < 0) {
    const selectedSailsEntries = Object.entries(selectedSails)
    const purgeItems = [...items].filter((item) => item.priceCategory === priceCategory)
    purgeItems.forEach((item) => {
      const { sailRefIdToPricePerSail, ownerSeqNs } = item
      const [sailRefId] = Object.keys(sailRefIdToPricePerSail)
      const [sailPackageCode] = selectedSailsEntries.find(
        (selectedSailEntry) => String(selectedSailEntry[1]) === sailRefId
      )
      ownerSeqNs.forEach((seqN) => {
        const request = {
          passenger: {
            backupRegistryUsed,
            priceCategory,
            personalIdentificationNumber,
          },
          seqN,
          sailPackageSeqN: sailPackagesSeqNums[sailPackageCode],
          sailPackage: sailPackageCode,
          sailRefIds: [sailRefId],
        }
        requests.push(request)
      })
    })

    return {
      reservationId,
      roundTrip,
      'purge-item': {
        requests,
      },
    }
  }

  const createRequestItem = (
    priceCategory,
    sailPackageCode,
    sailRefId,
    seqN,
    backupRegistryUsed,
    personalIdentificationNumber
    // eslint-disable-next-line max-params
  ) => {
    return {
      passenger: {
        priceCategory,
        backupRegistryUsed,
        personalIdentificationNumber,
      },
      sailPackage: sailPackageCode,
      sailRefIds: [sailRefId],
      seqN,
    }
  }

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < countThere; i++) {
    requests.push(
      createRequestItem(
        priceCategory,
        sailPackageCodeThere,
        sailRefIdThere,
        generator.generate(),
        backupRegistryUsed,
        personalIdentificationNumber
      )
    )
  }

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < countBack; i++) {
    requests.push(
      createRequestItem(
        priceCategory,
        sailPackageCodeBack,
        sailRefIdBack,
        generator.generate(),
        backupRegistryUsed,
        personalIdentificationNumber
      )
    )
  }

  return {
    roundTrip,
    reservationId,
    item: {
      requests,
    },
  }
}

export function* updateMultiCatsReservationGenerator(seats, guestSeqN) {
  const currentReservation = yield select(getCurrentReservation)
  const sailPackages = yield select(getSelectedSailPackages)
  const selectedSails = yield select(getSelectedSailRefIds)
  const reservationId = yield select(getCurrentReservationId)
  const items = yield select(getItems) || []
  const guests = yield select(getGuests) || []
  const sailPackagesSeqNums = yield select(getCodeSeqNumsMap)
  const editableTrip = yield select(getEditableTrip)
  const bicycleNums = yield select(getBicycleNumbers)
  const roundTrip = yield select(isRoundTrip)
  const sortSeqNs = (seqNums) =>
    Array.isArray(guests) ? guests.filter((guest) => seqNums.indexOf(guest.seqN) > -1).map((guest) => guest.seqN) : []
  const seatTransform = (seat) => {
    const {
      code: priceCategory,
      title,
      count = 1,
      subType = 'PASSENGER',
      plateNumber = '',
      localID: personalIdentificationNumber,
      backupRegistryUsed = false,
      customVehicleParams = {},
      status,
      handicapped,
      companyRegistrationNumber: companyRegNumber,
      manuallyChangedWeights: weightInKg,
      sailRefId,
      isAddon = false,
      attributeCode = '',
      inventoryClass,
    } = seat

    const companyRegistrationNumber = parseCompanyRegistrationNumber(companyRegNumber)

    const seqNumsForCategory = compose(prop('ownerSeqNs'), find(propEq('priceCategory', priceCategory)))

    let amount = count
    let itemKey = 'item'

    let getUids = uidsFromAmount

    if (guestSeqN && count === 0) {
      itemKey = 'purge-item'
      getUids = () => guestSeqN
    }

    if (count < 0) {
      itemKey = 'purge-item'
      amount = Math.abs(count)

      let purgeItems = [...items]

      if (sailRefId) {
        purgeItems = purgeItems.filter((item) => Object.keys(item.sailRefIdToPricePerSail).includes(sailRefId))
      }
      let seqNums = isBicycle(subType)
        ? isAddon
          ? getAddonNumbers(currentReservation, priceCategory)
          : bicycleNums
        : isAddon
        ? getAddonNumbers(currentReservation, priceCategory)
        : seqNumsForCategory(purgeItems)

      if (isAddon && !seqNums.length) {
        seqNums = getAddonNumbers(currentReservation, inventoryClass)
      }

      const isCurrentVehicle = (vehicle = {}) => vehicle.licencePlate.toLowerCase() === plateNumber.toLowerCase()
      const currentVehicleSeqN = pipe(
        propOr([], 'vehicles'),
        find(isCurrentVehicle),
        propOr(null, 'seqN')
      )(currentReservation)

      getUids = isTransport(subType) || isAddon ? flip(takeLast)(seqNums) : flip(takeLast)(sortSeqNs(seqNums))
      getUids = isTransport(subType) && isMaritime ? always([currentVehicleSeqN]) : getUids
    } else if (count === 0 && personalIdentificationNumber) {
      itemKey = 'purge-item'

      const getOneSeqN = compose(uniq, flatten, map(prop('ownerSeqNs')))

      const guestsSeqNs = compose(
        map(prop('seqN')),
        filter(propEq('personalIdentificationNumber', personalIdentificationNumber))
      )(guests)

      const guestsIncludeSeqN = compose(
        any((seqN) => contains(seqN, guestsSeqNs)),
        prop('ownerSeqNs')
      )
      const itemWithGuests = map((item) => assoc('ownerSeqNs', intersection(guestsSeqNs, item.ownerSeqNs), item))
      const getGuestNumsByLocalIds = compose(getOneSeqN, itemWithGuests, filter(guestsIncludeSeqN))

      getUids = () => getGuestNumsByLocalIds(items)
    }

    const requestsForTrip =
      (trip = {}) =>
      (requests) => {
        const notVehicleAndOfTripSailPackage = anyPass([
          // pathOr(false, ['vehicle', 'licencePlate']),
          propEq('sailPackage', trip.code),
        ])

        return has('code', trip) ? filter(notVehicleAndOfTripSailPackage, requests) : requests
      }

    const transformSeqNums = (seqNum) => {
      let ticketType = {
        [getTicketType(subType)]: {
          priceCategory,
          personalIdentificationNumber,
          backupRegistryUsed,
        },
      }
      if (isAddon) {
        if (attributeCode && title) {
          ticketType = {
            addon: {
              priceCategory,
              amount: 1,
              attributes: [
                {
                  attribute: {
                    code: attributeCode,
                  },
                  structure: {
                    __structure: 'single',
                    value: {
                      __type: 'catalog',
                      value: title,
                    },
                  },
                },
              ],
            },
          }
        } else {
          ticketType = {
            addon: {
              priceCategory,
              amount: 1,
            },
          }
        }
      }
      if (isTransport(subType) && !isAddon) {
        ticketType = {
          vehicle:
            status === 'verified'
              ? {
                  licencePlate: plateNumber,
                  ...(handicapped && { handicapped }),
                  ...(companyRegistrationNumber && { companyRegistrationNumber }),
                  ...(weightInKg && { weightOnSails: transformForWeights(weightInKg, selectedSails, sailPackages) }),
                }
              : {
                  priceCategory,
                  ...(handicapped && { handicapped }),
                  ...(companyRegistrationNumber && { companyRegistrationNumber }),
                  licencePlate: plateNumber,
                  ...customVehicleParams,
                },
        }
      }
      const ticketsRequestsMaker = map(transformForTickets(ticketType, selectedSails, seqNum, sailPackagesSeqNums))
      return ticketsRequestsMaker(sailPackages)
    }

    const allRequestsForAmount = compose(requestsForTrip(editableTrip), flatten, map(transformSeqNums), getUids)

    return {
      [itemKey]: {
        requests: allRequestsForAmount(amount),
      },
    }
  }

  const requestParts = compose(
    reduce((acc, val) => {
      const requests =
        acc[firstKey(val)] && acc[firstKey(val)].requests
          ? [...acc[firstKey(val)].requests, ...val[firstKey(val)].requests]
          : val[firstKey(val)].requests

      const vehicleRequests =
        acc[firstKey(val)] && acc[firstKey(val)].vehicleRequests
          ? [...acc[firstKey(val)].vehicleRequests, ...val[firstKey(val)].vehicleRequests]
          : val[firstKey(val)].vehicleRequests

      const addonsRequests =
        acc[firstKey(val)] && acc[firstKey(val)].addonsRequests
          ? [...acc[firstKey(val)].addonsRequests, ...val[firstKey(val)].addonsRequests]
          : val[firstKey(val)].addonsRequests

      return {
        ...acc,
        [firstKey(val)]: {
          ...(requests && { requests }),
          ...(vehicleRequests && { vehicleRequests }),
          ...(addonsRequests && { addonsRequests }),
        },
      }
    }, {}),
    map(seatTransform),
    values
  )(seats)

  return {
    roundTrip,
    reservationId,
    ...requestParts,
  }
}

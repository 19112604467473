import { put, select, takeEvery } from 'redux-saga/effects'

import { schedule, inventory } from '../../services'
import { selectSailAfterAllInventoriesFetch } from './selections'

const { fetchSailsForLegAndDate } = schedule.actions
const { fetchInventoryAction, changeDate } = inventory.actions

const { ALL_INVENTORY_FETCH_SUCCESS } = inventory.consts

export function* fetchSailsAndInventories({ sailPackage: { legSequence, code, taxi } = {}, dateSelector, ...payload }) {
  let date

  if (!legSequence) return

  if (payload.departureDate) {
    date = payload.departureDate
  } else if (dateSelector) {
    date = yield select(dateSelector)
  }

  if (date) {
    const isTaxi = Boolean(taxi)
    const params = { spCode: code, isTaxi, date, code, ...payload }

    yield put(fetchSailsForLegAndDate(params))
    yield put(fetchInventoryAction(params))
  }
}

export function* fetchAllInventory(action) {
  yield put(changeDate(action.payload))
}

export function* watchInventoriesFetch() {
  yield takeEvery(ALL_INVENTORY_FETCH_SUCCESS, selectSailAfterAllInventoriesFetch)
}

export default function* reservation() {
  yield [watchInventoriesFetch()]
}

import { pathOr } from 'ramda'

const getDataFromAction = pathOr({}, ['payload', 'data'])

import {
  RESERVATION_MODIFIED,
  RESERVATION_CREATE,
  RESERVATION_CREATED,
  RESERVATION_CONFIRM_SUCCESS,
  GET_RESERVATION_SUCCESS,
  GET_RESERVATION_FAILED,
  UPDATE_RESERVATION_INFO,
  REMOVE_CURRENT_RESERVATION,
  START_FETCHING,
  START_PRICE_CALCULATION,
  RESERVATION_CREATE_FAILED,
  RESERVATION_MODIFY_FAILED,
  CLEAR_RESERVATION,
  SET_RESERVATION,
  SET_REFUND_RESERVATION_FAILED,
  FETCH_PAYMENT_METHODS_SUCCESS,
  FETCH_PAYMENT_METHODS_FAILED,
  START_EDIT_RESERVATION_SUCCESS,
  CANCEL_EDIT_RESERVATION_SUCCESS,
  FINISH_EDIT_RESERVATION_SUCCESS,
  EDIT_DATA_IN_RESERVATION_SUCCESS,
  WATCH_CANCELLED_RESERVATION,
  GET_RESERVATION_ON_LOCALE,
  SET_EDITED_RESERVATION_DATA,
  CLEAR_RESERVATION_ERROR,
  EXTEND_RESERVATION_EXPIRE,
  APPLY_PROMO_CODE_SUCCESS,
  APPLY_PROMO_CODE_FAILED,
  REMOVE_PROMO_CODE_SUCCESS,
  REMOVE_PROMO_CODE_FAILED,
  RESERVATION_FROM_RESERVE,
  SET_SHOULD_FORBID_CLOSING_MODALS,
  RESERVATION_MODIFY,
} from '../consts/reservation'
import { updateReservation } from '../../../modules/Ticket/EditTicket/editTicketSagas'
import { FLUSH_SEAT } from '../../user-selections/consts'
import { isUnrecoverableError } from '../../../modules/Common/known-errors'

const initialState = {
  current: null,
  refund: null,
  edit: null,
  fetching: false,
  isPriceReady: true,
  payedReservations: {},
  paymentMethods: [],
  cancelled: false,
  modifying: false,
  isReservationForDriverFromReserve: false,
  shouldForbidClosingModals: false,
  status: 'idle',
}

const resErrorClean = {
  isPriceReady: true,
  fetching: false,
  failed: false,
  errorDetails: {},
  status: 'idle',
}

// eslint-disable-next-line complexity
const reservation = (state = initialState, action) => {
  switch (action.type) {
    case RESERVATION_CREATE:
    case RESERVATION_MODIFY: {
      return { ...state, status: 'progress' }
    }
    case SET_SHOULD_FORBID_CLOSING_MODALS:
      return { ...state, shouldForbidClosingModals: action.payload }
    case RESERVATION_CREATED:
      return { ...state, current: action.payload, ...resErrorClean }
    case RESERVATION_MODIFIED:
      return { ...state, current: action.payload, ...resErrorClean }
    case RESERVATION_CONFIRM_SUCCESS:
      return { ...state, current: action.payload.reservation, confirmed: true, ...resErrorClean }
    case UPDATE_RESERVATION_INFO:
      return { ...state, ...action.payload }
    case GET_RESERVATION_SUCCESS:
      return { ...state, exist: action.payload, ...resErrorClean }
    case GET_RESERVATION_ON_LOCALE:
      return { ...state, current: action.payload, ...resErrorClean }
    case SET_RESERVATION:
      return { ...state, [action.payload.type]: action.payload.reservation }
    case REMOVE_CURRENT_RESERVATION:
      return initialState
    case START_FETCHING:
      return { ...state, fetching: true }
    case RESERVATION_FROM_RESERVE:
      return { ...state, isReservationForDriverFromReserve: action.payload }
    case START_PRICE_CALCULATION:
      return { ...state, isPriceReady: false }
    case FLUSH_SEAT: {
      return { ...state, isPriceReady: true }
    }
    case 'modifying':
      return { ...state, modifying: action.payload }
    case RESERVATION_CREATE_FAILED:
      return {
        ...state,
        failed: 'RESERVATION CREATE FAILED',
        isPriceReady: true,
        errorDetails: getDataFromAction(action),
        status: 'idle',
      }
    case GET_RESERVATION_FAILED:
      return {
        ...state,
        fetching: false,
        failed: 'RESERVATION GET FAILED',
        errorDetails: getDataFromAction(action),
      }
    case APPLY_PROMO_CODE_SUCCESS:
      return { ...state, current: action.payload, ...resErrorClean }
    case APPLY_PROMO_CODE_FAILED:
      return {
        ...state,
        failed: 'RESERVATION PROMOTION CODE FAILED TO APPLY',
        isPriceReady: true,
        errorDetails: getDataFromAction(action),
        status: 'error',
      }
    case REMOVE_PROMO_CODE_FAILED:
      return {
        ...state,
        failed: 'RESERVATION PROMOTION CODE FAILED TO REMOVE',
        isPriceReady: true,
        errorDetails: getDataFromAction(action),
        status: 'error',
      }
    case REMOVE_PROMO_CODE_SUCCESS:
      return {
        ...state,
        current: {
          ...state.current,
          promotion: undefined,
        },
        ...resErrorClean,
      }
    case CLEAR_RESERVATION_ERROR:
      return {
        ...state,
        ...resErrorClean,
      }
    case RESERVATION_MODIFY_FAILED: {
      const errorDetails = getDataFromAction(action)
      const { code = '' } = errorDetails

      return {
        ...state,
        ...(isUnrecoverableError(code) && { current: null }),
        fetching: false,
        isPriceReady: true,
        failed: 'RESERVATION MODIFY FAILED',
        errorDetails,
        status: 'error',
      }
    }
    case SET_REFUND_RESERVATION_FAILED:
      return { ...state, refund: null, fetching: false, failed: 'RESERVATION REFUND INFO FAILED' }

    case FETCH_PAYMENT_METHODS_SUCCESS:
      return { ...state, ...action.payload }
    case FETCH_PAYMENT_METHODS_FAILED:
      return { ...state, paymentMethods: [] }
    case START_EDIT_RESERVATION_SUCCESS: {
      return {
        ...state,
        edit: {
          ...state.edit,
          started: true,
          reservation: {
            ...action.payload,
          },
          originalReservation: {
            ...action.payload,
          },
        },
      }
    }
    case CANCEL_EDIT_RESERVATION_SUCCESS: {
      return {
        ...state,
        edit: {
          started: false,
          ...state.edit,
          reservation: {},
        },
      }
    }
    case FINISH_EDIT_RESERVATION_SUCCESS: {
      return {
        ...state,
        edit: {
          ...state.edit,
          started: false,
          reservation: {
            ...action.payload,
          },
        },
      }
    }
    case EDIT_DATA_IN_RESERVATION_SUCCESS: {
      return {
        ...state,
        edit: {
          ...state.edit,
          reservation: {
            ...action.payload,
          },
        },
      }
    }
    case WATCH_CANCELLED_RESERVATION: {
      return {
        ...state,
        cancelled: action.payload,
      }
    }
    case EXTEND_RESERVATION_EXPIRE: {
      return {
        ...state,
        current: {
          ...state.current,
          expireAt: action.payload,
        },
        edit: {
          ...state.edit,
          reservation: {
            ...state.edit.reservation,
            expireAt: action.payload,
            modifiedReservation: {
              ...state.edit.reservation.modifiedReservation,
            },
          },
        },
      }
    }
    case CLEAR_RESERVATION:
      return initialState
    case 'persist/PURGE': {
      return initialState
    }
    case SET_EDITED_RESERVATION_DATA: {
      if (action.payload.field === 'date') {
        return {
          ...state,
          edit: {
            ...state.edit,
            editedReservation: {
              ...state.edit.editedReservation,
              departureDate: action.payload.param,
            },
          },
        }
      }
      if (action.payload.field === 'time') {
        return {
          ...state,
          edit: {
            ...state.edit,
            editedReservation: {
              ...state.edit.editedReservation,
              departureTime: action.payload.param,
            },
          },
        }
      }
      return state
    }

    case updateReservation.toString(): {
      return {
        ...state,
        edit: {
          ...state.edit,
          reservation: action.payload,
        },
      }
    }

    default:
      return state
  }
}

export default reservation
